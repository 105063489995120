import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ITimeslot } from "../types/ITimeslot";

interface TimeslotsSelectorProps {
  showAllTimeslotOption: boolean;
  onChange: (t: string) => void;
}

const timeSlots = [
  {
    Id: 1,
    Time: "9am to 12pm",
    Name: "9am to 12pm",
  },
  {
    Id: 2,
    Time: "2pm to 5.30pm",
    Name: "2pm to 5.30pm",
  },
  {
    Id: 4,
    Time: "6pm to 9pm",
    Name: "6pm to 9pm",
  },
];

const allTimeSlots = [
  {
    Id: 3,
    Time: "-",
    Name: "All time slots",
  },
].concat(timeSlots);

function TimeslotsSelector({
  onChange,
  showAllTimeslotOption,
}: TimeslotsSelectorProps) {
  const slots = (
    showAllTimeslotOption ? allTimeSlots : timeSlots
  ) as ITimeslot[];

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="select-timeslot-label">Select Timeslot</InputLabel>
      <Select
        labelId="select-timeslot-label"
        id="select-timeslot"
        label="Select timeslot"
        defaultValue=""
        onChange={handleChange}
      >
        {slots.map((s) => (
          <MenuItem key={s.Id} value={s.Time}>
            {s.Name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TimeslotsSelector;
