import React, { createContext } from "react";
import { IAuthContext } from "../types/IAuthContext";
import Auth from "../repositories/Auth";

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [token, setToken] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [role, setRole] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    // On load, check for token
    setToken(Auth.getToken());

    return () => {
      setToken(null);
    };
  }, [setToken]);

  React.useEffect(() => {
    // On load, check for role when token changes
    async function getRole() {
      setRole(token ? await Auth.getRole(token) : undefined);
    }
    // eslint-disable-next-line no-void
    void getRole();

    return () => {
      setRole(undefined);
    };
  }, [token, setRole]);

  const signIn = React.useCallback(
    async (username: string, password: string) => {
      setIsLoading(true);
      try {
        const checkToken = await Auth.signIn(username, password);
        setError(checkToken ? undefined : "No token returned.");
        setToken(checkToken);
      } catch (e) {
        if (typeof e === "string") {
          setError(e);
        } else if (e instanceof Error) {
          setError(e.message);
        }
      }

      setIsLoading(false);
    },
    []
  );

  const logout = React.useCallback(() => {
    setIsLoading(true);
    Auth.logout();
    setError(undefined);
    setToken(null);
    setIsLoading(false);
  }, []);

  const memoedValue = React.useMemo(
    (): IAuthContext => ({
      token,
      signIn,
      logout,
      error,
      role,
      isLoading,
    }),
    [token, signIn, logout, error, role, isLoading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}
