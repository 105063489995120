import React from "react";
import { Box, Button, Typography, colors, styled } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import useAuth from "../hooks/useAuth";

const TopNavBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#002c5f",
});

const StyledLogoutBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: "1em",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
})) as typeof Button;

const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0.5em 1em",
  lineHeight: 0,
  "& > img": {
    height: 30,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const LogoTitle = styled(Typography)(({ theme }) => ({
  color: colors.common.white,
  marginLeft: 12,
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    marginLeft: 0,
  },
}));

const TopNavSpacer = styled(Box)({
  flex: 1,
});

const StagingTitle = styled(Typography)({
  backgroundColor: colors.yellow[900],
  color: colors.common.white,
  padding: "0 0.5rem",
  borderRadius: "0.1rem",
}) as typeof Typography;

function TopNav() {
  const isStaging = process.env.REACT_APP_ENV !== "PRD";
  const { token, logout } = useAuth();

  return (
    <TopNavBox>
      <LogoBox>
        <img src="/ctdi-flat-logo-white.png" alt="CTDI" />
        <LogoTitle>Transportation System</LogoTitle>
      </LogoBox>
      {isStaging && <StagingTitle>UAT Site</StagingTitle>}
      <TopNavSpacer />
      {token && (
        <StyledLogoutBtn
          variant="text"
          endIcon={<LogoutIcon aria-label="logout" />}
          onClick={() => logout()}
        >
          Logout
        </StyledLogoutBtn>
      )}
    </TopNavBox>
  );
}

export default TopNav;
