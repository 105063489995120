import { IJwtToken } from "../types/IJwtToken";
import { IRole } from "../types/IRole";
import Config from "./Config";
import Http from "./Http";

const config = Config.url();
const authSessionName = "ctdi-ts-auth-jwt";

export default class Auth {
  /**
   * Sign in with username and password
   * @param username
   * @param password
   * @returns
   */
  static async signIn(
    username: string,
    password: string
  ): Promise<string | null> {
    if (!username || !password) {
      return null;
    }

    const authResponse = await Http.post<IJwtToken>(`${config.url}/api/Auth`, {
      body: JSON.stringify({
        userid: username,
        password,
      }),
    });

    if (authResponse.Success) {
      sessionStorage.setItem(authSessionName, JSON.stringify(authResponse));
      return authResponse.jwtToken;
    }

    throw new Error(authResponse.Message ?? "Unable to login.");
  }

  /**
   * Logout: removes token from session storage
   */
  static logout() {
    sessionStorage.removeItem(authSessionName);
  }

  /**
   * Get role of signed in user
   * @param token
   * @returns
   */
  static async getRole(token: string): Promise<string | undefined> {
    if (token) {
      const roleResponse = await Http.get<IRole>(
        token,
        `${config.url}/api/roles`
      );
      return roleResponse.Role;
    }

    return undefined;
  }

  /**
   * Get token from session storage, user must be signed in first
   * @returns
   */
  static getToken(): string | null {
    const authSession = sessionStorage.getItem(authSessionName);
    if (authSession) {
      const authResponse = JSON.parse(authSession) as IJwtToken;
      return authResponse.jwtToken;
    }

    return null;
  }
}
