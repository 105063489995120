/* eslint-disable no-void */
import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import PhotoList from "./PhotoList";
import useAuth from "../hooks/useAuth";
import { IJobPhoto } from "../types/IJobPhoto";
import { JobViewPhotosFunc } from "../repositories/Jobs";
import Loading from "./Loading";
import { JobDialogProps } from "./JobAssignDialog";

interface PhotoDialogProps extends JobDialogProps {
  onViewPhotos: JobViewPhotosFunc;
  onSelectedPhoto: (p: IJobPhoto) => void;
  onSelectedDeletePhoto: (p: IJobPhoto) => void;
  onClickUpload: () => void;
  visible: boolean;
}

function PhotoDialog({
  jobId,
  setJobId,
  onViewPhotos,
  onSelectedPhoto,
  onSelectedDeletePhoto,
  onClickUpload,
  visible,
}: PhotoDialogProps) {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  const { token, role } = useAuth();
  const [photos, setPhotos] = React.useState<IJobPhoto[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const isManager: boolean | undefined = React.useMemo(() => {
    if (role) {
      const userRoles = role.split(",");
      return !!userRoles?.find(
        (r) =>
          r.toLowerCase() === "manager" ||
          r.toLowerCase() === "delivery manager"
      );
    }

    return undefined;
  }, [role]);

  React.useEffect(() => {
    if (token && jobId && visible) {
      const loadPhotos = async () => {
        const loaded = await onViewPhotos(jobId);
        setPhotos(loaded);
        setIsLoading(false);
      };

      void loadPhotos();
    }

    return () => {
      setPhotos([]);
      setIsLoading(true);
    };
  }, [token, jobId, onViewPhotos, visible]);

  return (
    <Dialog
      fullWidth
      fullScreen={matchDownMd}
      open={!!jobId && visible}
      onClose={() => setJobId(undefined)}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle
        sx={{
          fontSize: "16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>Uploaded Photos for Job {jobId}</Box>
        {isManager && (
          <Button
            variant="contained"
            size="small"
            startIcon={<UploadIcon />}
            sx={{ ml: 2, mr: 2 }}
            onClick={onClickUpload}
          >
            Upload
          </Button>
        )}
        <Box sx={{ flex: 1 }} />
        <IconButton
          aria-label="close"
          onClick={() => setJobId(undefined)}
          size="small"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: () => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <PhotoList
            photos={photos}
            onSelectedPhoto={onSelectedPhoto}
            onSelectedDeletePhoto={onSelectedDeletePhoto}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PhotoDialog;
