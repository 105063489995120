import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IJob } from "../types/IJob";
import { IJobType, JobTypeMapping } from "../types/IJobType";
import AddressBlock from "./AddressBlock";
import PhotoDialog from "./PhotoDialog";
import { JobViewPhotosFunc } from "../repositories/Jobs";
import { IJobPhoto } from "../types/IJobPhoto";
import PhotoViewer from "./PhotoViewer";

const StyledItemTypography = styled(Typography)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: 3,
  fontSize: "14px",
  "& span.list-item--heading": {
    fontWeight: "bold",
    minWidth: "74px",
  },
}) as typeof Typography;

const StyledComments = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 3,
  fontSize: "14px",
  "& span:first-of-type": {
    fontWeight: "bold",
  },
}) as typeof Typography;

const StyledSecondaryText = styled(Typography)({
  fontSize: 11,
}) as typeof Typography;

interface JobsListProps {
  jobs: IJob[];
  jobType: IJobType;
  onActionClick: (action: "complete" | "failed", jobId: number) => void;
  onViewPhotos: JobViewPhotosFunc;
}

function JobsList({
  jobs,
  jobType,
  onActionClick,
  onViewPhotos,
}: JobsListProps) {
  const { palette } = useTheme();
  const [photosJobId, setPhotosJobId] = useState<number>();

  const [selectedPhoto, setSelectedPhoto] = React.useState<IJobPhoto>();
  const [showSinglePhoto, setShowSinglePhoto] = React.useState<boolean>(false);
  const [showPhotoList, setShowPhotoList] = React.useState<boolean>(false);

  const handleOpenPhotoList = React.useCallback((jobId: number) => {
    setSelectedPhoto(undefined);
    setShowSinglePhoto(false);
    setPhotosJobId(jobId);
    setShowPhotoList(true);
  }, []);

  const handleClosePhotoViewer = React.useCallback(() => {
    setShowSinglePhoto(false);
    setShowPhotoList(true);
  }, []);

  const handleSelectPhoto = React.useCallback((photo: IJobPhoto) => {
    setSelectedPhoto(photo);
    setShowPhotoList(false);
    setShowSinglePhoto(true);
  }, []);

  return (
    <List sx={{ width: "100%" }} data-testid="jobs-list">
      {jobs.length > 0 &&
        jobs.map((row) => (
          <ListItem
            key={row.Id}
            alignItems="flex-start"
            data-testid="jobs-list-item"
            dense
            sx={{ borderBottom: "1px solid #eee" }}
          >
            <ListItemAvatar>
              <Avatar>
                <LocalShippingIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              secondary={
                <>
                  <StyledSecondaryText
                    component="span"
                    sx={{ display: "block" }}
                  >
                    Created on:{" "}
                    {new Date(row.Created_Date).toLocaleDateString()}
                  </StyledSecondaryText>
                  <StyledSecondaryText
                    component="span"
                    sx={{ display: "block" }}
                  >
                    Modified on:{" "}
                    {new Date(row.Modified_Date).toLocaleDateString()}
                  </StyledSecondaryText>
                </>
              }
            >
              <StyledItemTypography component="span">
                <span className="list-item--heading">Job #:</span>
                <span>{row.Id}</span>
              </StyledItemTypography>
              <StyledItemTypography component="span">
                <span className="list-item--heading">Order #:</span>
                <span>{row.So_Nbr || row.Ord_Nbr}</span>
              </StyledItemTypography>
              <StyledItemTypography component="span">
                <span className="list-item--heading">Address:</span>
                <AddressBlock job={row} />
              </StyledItemTypography>
              <StyledItemTypography component="span">
                <span className="list-item--heading">Time Slot:</span>
                <span>{row.Driver_Timeslot}</span>
              </StyledItemTypography>
              <StyledItemTypography component="span">
                <span className="list-item--heading">Vehicle:</span>
                <span>{row.Vehicle_type}</span>
              </StyledItemTypography>
              {["R", "D"].includes(jobType) && (
                <StyledComments component="span">
                  <span className="list-item--heading">
                    {JobTypeMapping[jobType]} Message:
                  </span>
                  <span>{row.Delivery_Comments}</span>
                </StyledComments>
              )}
              {["R", "D", "Z"].includes(jobType) && (
                <Button
                  onClick={() => handleOpenPhotoList(row.Id)}
                  variant="contained"
                  size="small"
                  sx={{ mt: 1, mb: 2 }}
                  startIcon={<PhotoLibraryIcon />}
                >
                  Uploaded Photos
                </Button>
              )}
            </ListItemText>
            <ListItemSecondaryAction
              sx={{
                display: "flex",
                height: "90%",
                flexDirection: "column",
              }}
            >
              {jobType === "A" && (
                <>
                  <IconButton
                    edge="end"
                    aria-label="complete"
                    color="success"
                    onClick={() => onActionClick("complete", row.Id)}
                  >
                    <TaskAltIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="failed"
                    color="error"
                    onClick={() => onActionClick("failed", row.Id)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      {jobs.length === 0 && (
        <Box
          sx={{
            p: 2,
            width: "100%",
            backgroundColor: palette.grey[100],
          }}
        >
          <Typography>No Job Found</Typography>
        </Box>
      )}
      <PhotoDialog
        jobId={photosJobId}
        setJobId={setPhotosJobId}
        onViewPhotos={onViewPhotos}
        onSelectedPhoto={handleSelectPhoto}
        onSelectedDeletePhoto={() => null}
        onClickUpload={() => null}
        visible={showPhotoList}
      />
      {selectedPhoto && (
        <PhotoViewer
          photo={selectedPhoto}
          visible={showSinglePhoto}
          onClose={() => handleClosePhotoViewer()}
        />
      )}
    </List>
  );
}

export default JobsList;
