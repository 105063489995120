import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IVehicleType } from "../types/IVehicleType";

interface VehicleTypesSelectorProps {
  vehicles: IVehicleType[];
  onChange: (v: string) => void;
}

function VehicleTypesSelector({
  vehicles,
  onChange,
}: VehicleTypesSelectorProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="select-vehicletype-label">Select Vehicle Type</InputLabel>
      <Select
        labelId="select-vehicletype-label"
        id="select-vehicletype"
        label="Select vehicletype"
        defaultValue=""
        onChange={handleChange}
      >
        {vehicles.map((v) => (
          <MenuItem key={v.Id} value={v.Id}>
            {v.VehicleName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default VehicleTypesSelector;
