import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { IJobPhoto } from "../types/IJobPhoto";
import { getFileType } from "./PhotoList";

type PhotoViewerProps = {
  photo: IJobPhoto;
  visible: boolean;
  onClose: (id: number | string) => void;
};

export default function PhotoViewer({
  photo,
  visible,
  onClose,
}: PhotoViewerProps) {
  return (
    <Dialog
      fullWidth
      fullScreen
      open={visible}
      onClose={() => onClose(photo.Job_Id)}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle sx={{ fontSize: "16px" }}>
        {photo.FileName} (Photo of Job {photo.Job_Id})
        <IconButton
          aria-label="close"
          onClick={() => onClose(photo.Job_Id)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (_theme) => _theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {photo && (
          <img
            src={`data:${
              getFileType(photo.FileName) || "image/png"
            };charset=utf-8;base64,${photo.ImageData}`}
            srcSet={`data:${
              getFileType(photo.FileName) || "image/png"
            };charset=utf-8;base64,${photo.ImageData}`}
            alt={photo.FileName}
            style={{
              maxWidth: "100%",
            }}
            loading="lazy"
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
