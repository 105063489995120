/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import DriversSelector from "./DriversSelector";
import { IDriver } from "../types/IDriver";
import { IVehicleType } from "../types/IVehicleType";
import VehicleTypesSelector from "./VehicleTypesSelector";
import TimeslotsSelector from "./TimeslotsSelector";
import ErrorDialogContentText from "./ErrorDialogContentText";
import { IGenericPostResponse } from "../types/IGenericPostResponse";

interface JobBulkAssignDialogProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  jobIds: number[];
  drivers: IDriver[];
  vehicles: IVehicleType[];
  onCancel: () => void;
  onBulkAssign: (
    driver: string,
    slot: string,
    vehicleId: string
  ) => Promise<IGenericPostResponse>;
  reload: () => void;
}

function JobBulkAssignDialog({
  open,
  setOpen,
  jobIds,
  drivers,
  vehicles,
  onCancel,
  onBulkAssign,
  reload,
}: JobBulkAssignDialogProps) {
  const [selectedDriver, setSelectedDriver] = React.useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = React.useState<string>("");
  const [selectedSlot, setSelectedSlot] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const handleAssign = React.useCallback(async () => {
    if (
      jobIds.length === 0 ||
      !selectedDriver ||
      !selectedSlot ||
      selectedVehicle === ""
    ) {
      setError(
        "All fields are required. If vehicle is not applicable, choose NA."
      );
      return;
    }

    setIsSaving(true);
    const response = await onBulkAssign(
      selectedDriver,
      selectedSlot,
      selectedVehicle
    );

    if (response.status === "success") {
      setSelectedDriver("");
      setSelectedSlot("");
      setSelectedVehicle("");
      setError("");
      setIsSaving(false);
      reload();
      setOpen(false);
    } else {
      setError(
        (response.Message as string) ??
          "Server returned error. Please try again."
      );
    }
    setIsSaving(false);
  }, [
    jobIds.length,
    selectedDriver,
    selectedSlot,
    selectedVehicle,
    onBulkAssign,
    reload,
    setOpen,
  ]);

  const handleClose = () => {
    onCancel();
    reload();
    setSelectedDriver("");
    setSelectedSlot("");
    setSelectedVehicle("");
    setError("");
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiFormControl-root": {
          marginBottom: "1em",
        },
      }}
    >
      <DialogTitle>Bulk Assign Job</DialogTitle>
      <DialogContent>
        <DialogContentText>Job IDs: {jobIds.join(",")}</DialogContentText>
        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
        <DriversSelector drivers={drivers} onChange={setSelectedDriver} />
        <TimeslotsSelector
          onChange={setSelectedSlot}
          showAllTimeslotOption={false}
        />
        <VehicleTypesSelector
          vehicles={vehicles}
          onChange={setSelectedVehicle}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button onClick={handleAssign} variant="contained" disabled={isSaving}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobBulkAssignDialog;
