/* eslint-disable no-void */
import React from "react";
import { AuthContext } from "../contexts/AuthContext";
import { IJob } from "../types/IJob";
import Jobs from "../repositories/Jobs";
import { IJobType } from "../types/IJobType";
import { IPagination } from "../types/IPagination";

export default function useJobs(
  jobType: IJobType = "O",
  orderBy: "ASC" | "DESC" = "ASC",
  page: number = 1,
  filterFromDate?: string,
  filterToDate?: string,
  orderNumber?: string,
  driver?: string,
  timeslot?: string
) {
  const { token } = React.useContext(AuthContext);
  const [jobs, setJobs] = React.useState<IJob[]>([]);
  const [pagination, setPagination] = React.useState<IPagination>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  /**
   * Reload all jobs
   */
  const reload = React.useCallback(() => {
    if (token) {
      void (async () => {
        setJobs([]);
        setIsLoading(true);

        // Date input format yyyy-mm-dd
        // Change it to format mm/dd/yyyy
        let fromDate = "";
        if (filterFromDate) {
          const dateArray = filterFromDate.split("-");
          fromDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
        }
        let toDate = "";
        if (filterToDate) {
          const dateArray = filterToDate.split("-");
          toDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
        }

        try {
          const response = await Jobs.getAll({
            token,
            jobType,
            orderBy,
            page,
            from_date: fromDate,
            to_date: toDate,
            Ord_Nbr: orderNumber,
            driver_id: driver,
          });
          if (timeslot && timeslot !== "-") {
            setJobs(
              response.Data.filter((ts) => ts.Driver_Timeslot === timeslot)
            );
          } else {
            setJobs(response.Data);
          }
          setPagination(response.Pagination ?? undefined);
        } catch (e) {
          if (e instanceof Error) setError(e.message);
          else setError(String(e));
        }
        setIsLoading(false);
      })();
    }

    return () => {
      setPagination(undefined);
      setIsLoading(false);
      setJobs([]); // Reset to empty array on dismount
    };
  }, [
    token,
    jobType,
    orderBy,
    page,
    filterFromDate,
    filterToDate,
    orderNumber,
    driver,
    timeslot,
  ]);

  React.useEffect(() => {
    reload();

    return () => {
      setPagination(undefined);
      setIsLoading(false);
      setJobs([]); // Reset to empty array on dismount
      setError("");
    };
  }, [reload]);

  return {
    error,
    jobs,
    isLoading,
    pagination,
    reload,
  };
}
