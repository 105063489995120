import { Container, Typography, styled } from "@mui/material";
import React from "react";

const StyledContainer = styled(Container)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: "2em",
});

const StyledTitle = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: "bold",
  textAlign: "center",
}));

const StyleBody = styled(Typography)({
  textAlign: "center",
});

function NotFound() {
  return (
    <StyledContainer id="not-found" data-testid="not-found">
      <StyledTitle>Oops, infamous 404!</StyledTitle>
      <StyleBody>
        The page you&apos;re trying to access does not exist.
      </StyleBody>
    </StyledContainer>
  );
}

export default NotFound;
