import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#002c5f",
    },
  },
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-selected": {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.grey["100"],
          },
        }),
      },
    },
  },
});

export default muiTheme;
