/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import DriversSelector from "./DriversSelector";
import { IDriver } from "../types/IDriver";
import { IVehicleType } from "../types/IVehicleType";
import VehicleTypesSelector from "./VehicleTypesSelector";
import TimeslotsSelector from "./TimeslotsSelector";
import { JobAssignFunc } from "../repositories/Jobs";
import ErrorDialogContentText from "./ErrorDialogContentText";

export interface JobDialogProps {
  jobId: number | undefined;
  setJobId: (x: number | undefined) => void;
}

interface JobAssignDialogProps extends JobDialogProps {
  drivers: IDriver[];
  vehicles: IVehicleType[];
  onAssign: JobAssignFunc;
  reload: () => void;
}

function JobAssignDialog({
  jobId,
  setJobId,
  drivers,
  vehicles,
  onAssign,
  reload,
}: JobAssignDialogProps) {
  const [selectedDriver, setSelectedDriver] = React.useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = React.useState<string>("");
  const [selectedSlot, setSelectedSlot] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const handleAssign = React.useCallback(async () => {
    if (!jobId || !selectedDriver || !selectedSlot || selectedVehicle === "") {
      setError(
        "All fields are required. If vehicle is not applicable, choose NA."
      );
      return;
    }

    setIsSaving(true);
    const response = await onAssign(
      jobId,
      selectedDriver,
      selectedSlot,
      selectedVehicle
    );

    if (response.status === "success") {
      setSelectedDriver("");
      setSelectedSlot("");
      setSelectedVehicle("");
      setError("");
      setIsSaving(false);
      reload();
      setJobId(undefined);
    } else {
      setError(
        (response.Message as string) ??
          "Server returned error. Please try again."
      );
    }
    setIsSaving(false);
  }, [
    jobId,
    selectedDriver,
    selectedSlot,
    selectedVehicle,
    onAssign,
    setJobId,
    reload,
  ]);

  const handleClose = () => {
    reload();
    setSelectedDriver("");
    setSelectedSlot("");
    setSelectedVehicle("");
    setError("");
    setJobId(undefined);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!jobId}
      onClose={handleClose}
      sx={{
        "& .MuiFormControl-root": {
          marginBottom: "1em",
        },
      }}
    >
      <DialogTitle>Assign Job</DialogTitle>
      <DialogContent>
        <DialogContentText>Job ID: {jobId}</DialogContentText>
        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
        <DriversSelector drivers={drivers} onChange={setSelectedDriver} />
        <TimeslotsSelector
          onChange={setSelectedSlot}
          showAllTimeslotOption={false}
        />
        <VehicleTypesSelector
          vehicles={vehicles}
          onChange={setSelectedVehicle}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button onClick={handleAssign} variant="contained" disabled={isSaving}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobAssignDialog;
