/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { JobCancelFunc } from "../repositories/Jobs";
import ErrorDialogContentText from "./ErrorDialogContentText";
import { JobDialogProps } from "./JobAssignDialog";

export interface JobCancelDialogProps extends JobDialogProps {
  onCancel: JobCancelFunc;
  reload: () => void;
}

function JobCancelDialog({
  jobId,
  setJobId,
  onCancel,
  reload,
}: JobCancelDialogProps) {
  const [comments, setComments] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const handleJobCancel = React.useCallback(async () => {
    if (!jobId || !comments) {
      setError("You must include a reason for cancellation.");
      return;
    }

    setIsSaving(true);
    const response = await onCancel(jobId, comments);
    if (response.status === "success") {
      setComments("");
      setError("");
      setIsSaving(false);
      reload();
      setJobId(undefined);
    } else {
      setError(
        (response.Message as string) ??
          "Server returned error. Please try again."
      );
    }
    setIsSaving(false);
  }, [comments, jobId, setJobId, onCancel, reload]);

  const handleClose = () => {
    setComments("");
    setError("");
    setJobId(undefined);
  };

  return (
    <Dialog
      open={!!jobId}
      onClose={handleClose}
      aria-labelledby="alert-dialog-cancel-title"
      aria-describedby="alert-dialog-cancel-description"
    >
      <DialogTitle id="alert-dialog-cancel-title">
        Cancel selected Job?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-cancel-description">
          To cancel the selected Job, please enter your reason of cancellation.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Reason for cancellation"
          type="text"
          fullWidth
          variant="standard"
          multiline
          onChange={(e) => setComments(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button
          onClick={handleJobCancel}
          variant="contained"
          disabled={isSaving}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobCancelDialog;
