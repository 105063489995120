/**
 * Converts Date to string for MUI Input, e.g. yyyy-MM-dd
 * @param d Date
 * @returns yyyy-MM-dd
 */
export function dateToMuiString(d: Date) {
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
}

/**
 * Converts Date to string for CTDI API, e.g. MM-dd-yyyy
 * @param d Date
 * @returns MM-dd-yyyy
 */
export function dateToApiString(d: Date) {
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [month, day, year].join("-");
}
