import React from "react";
import { Box, Stack } from "@mui/material";
import TopNav from "./TopNav";

interface LayoutProps {
  children: React.ReactNode;
}

/**
 * Single column Layout
 * @returns
 */
function Layout({ children }: LayoutProps) {
  return (
    <Stack id="layout-header" sx={{ width: "100%" }}>
      <TopNav />
      <Box id="layout-body" sx={{ flex: 1 }}>
        {children}
      </Box>
    </Stack>
  );
}

export default Layout;
