/* eslint-disable react/no-array-index-key */
import React from "react";
import { Box, Link, Typography, styled } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import { IJob } from "../types/IJob";

const StyledText = styled(Typography)({
  display: "block",
  fontWeight: "400",
  fontSize: "0.875rem",
  lineHeight: "1.43",
}) as typeof Typography;

function AddressBlock({ job }: { job: IJob }) {
  const address = [job.Shipto_Address1];

  if (job.Shipto_Address2) {
    address.push(job.Shipto_Address2);
  }
  if (job.Shipto_Address3) {
    address.push(job.Shipto_Address3);
  }
  if (job.Shipto_City) {
    address.push(job.Shipto_City);
  }
  if (job.Shipto_State) {
    address.push(job.Shipto_State);
  }
  if (job.Shipto_Country) {
    address.push(job.Shipto_Country);
  }
  if (job.Shipto_Zip) {
    address.push(job.Shipto_Zip);
  }

  return (
    <Box className="address-block" component="span">
      {job.Contact_Name && (
        <StyledText component="span">Attn: {job.Contact_Name}</StyledText>
      )}
      {job.Phone && (
        <StyledText component="span">
          Phone:{" "}
          <Link href={`tel:${job.Phone.replaceAll(" ", "")}`} rel="noopener">
            {job.Phone}
          </Link>
        </StyledText>
      )}
      {job.Mobile && (
        <StyledText component="span">
          Mobile:{" "}
          <Link href={`tel:${job.Mobile.replaceAll(" ", "")}`} rel="noopener">
            {job.Mobile}
          </Link>
        </StyledText>
      )}
      {job.Email && (
        <StyledText component="span">Email: {job.Email}</StyledText>
      )}
      {job.Shipto_Name && (
        <StyledText component="span">{job.Shipto_Name}</StyledText>
      )}
      {address.map((line, i) => (
        <StyledText key={`address-${i}`} component="span">
          {line}
        </StyledText>
      ))}
      {job.Ship_Instruction && (
        <Box component="span">
          <StyledText component="span">Ship Instruction</StyledText>
          <StyledText component="span">{job.Ship_Instruction}</StyledText>
        </Box>
      )}

      <Link
        href={`https://www.google.com.sg/maps/search/${address.join(" ")}/`}
        target="_blank"
        rel="noopener"
      >
        Show Map <PlaceIcon sx={{ fontSize: "1em" }} />
      </Link>
    </Box>
  );
}

export default AddressBlock;
