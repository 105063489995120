/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import ErrorDialogContentText from "./ErrorDialogContentText";
import { IGenericPostResponse } from "../types/IGenericPostResponse";
import SuccessDialogContentText from "./SuccessDialogContentText";

export interface PhotoUploadDialogProps {
  jobId: number;
  visible: boolean;
  onUpload: (jobId: number, files: File[]) => Promise<IGenericPostResponse>;
  onClose: () => void;
}

function PhotoUploadDialog({
  jobId,
  visible,
  onUpload,
  onClose,
}: PhotoUploadDialogProps) {
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<File[] | undefined>(undefined);
  const [uploadStatus, setUploadStatus] = React.useState<{
    status: "success" | "error";
    message: string;
  }>();

  const handleClickComplete = React.useCallback(async () => {
    if (!jobId || !files) {
      setUploadStatus({
        status: "error",
        message: "All fields are required.",
      });
      return;
    }

    setIsSaving(true);

    // Upload all the files
    const response = await onUpload(jobId, files);

    if (response.status === "success") {
      setFiles(undefined);
      setIsSaving(false);
      setUploadStatus({
        status: "success",
        message: `Photo uploaded to job # ${jobId}.`,
      });
    } else {
      setUploadStatus({
        status: "error",
        message: "Server returned error. Please try again.",
      });
    }

    setIsSaving(false);
  }, [files, jobId, onUpload]);

  const handleClose = () => {
    setUploadStatus(undefined);
    setFiles(undefined);
    onClose();
  };

  return (
    <Dialog
      open={visible && !!jobId}
      onClose={handleClose}
      aria-labelledby="alert-dialog-upload-title"
      aria-describedby="alert-dialog-upload-description"
    >
      <DialogTitle id="alert-dialog-upload-title">
        Upload photo to the job?
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 1, fontWeight: "700" }}>
          Job #: {jobId}
        </Typography>
        <DialogContentText
          id="alert-dialog-upload-description"
          sx={{ marginBottom: 3 }}
        >
          Attach photos as proof.
        </DialogContentText>
        <MuiFileInput
          label="Upload photo"
          value={files}
          onChange={(newFiles) => setFiles(newFiles)}
          inputProps={{ accept: ".png, .jpeg" }}
          multiple
        />
        {files && files.length > 1 && (
          <Box sx={{ p: 1 }}>
            <Typography variant="body1" sx={{ color: "#999" }}>
              Selected files:
            </Typography>
            {files.map((f) => (
              <Typography variant="body2" key={f.name}>
                {f.name}
              </Typography>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button
          onClick={handleClickComplete}
          variant="contained"
          disabled={isSaving}
        >
          Submit
        </Button>
      </DialogActions>
      {uploadStatus && uploadStatus.status === "error" && (
        <ErrorDialogContentText sx={{ pl: 3, pr: 3, pb: 2 }}>
          {uploadStatus.message}
        </ErrorDialogContentText>
      )}
      {uploadStatus && uploadStatus.status === "success" && (
        <SuccessDialogContentText sx={{ pl: 3, pr: 3, pb: 2 }}>
          {uploadStatus.message}
        </SuccessDialogContentText>
      )}
    </Dialog>
  );
}

export default PhotoUploadDialog;
