/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IJobType, JobTypeMapping } from "../types/IJobType";

interface JobTypeFilterButtonProps {
  filterJobs: IJobType;
  setFilterJobs: (s: IJobType) => void;
  reload: () => void;
}

const menuItems = ["O", "A", "C", "D", "R", "Z"];

function JobTypeFilterButton({
  filterJobs,
  setFilterJobs,
  reload,
}: JobTypeFilterButtonProps) {
  const [openDropDown, setOpenDropDown] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const handleCloseDropdown = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenDropDown(false);
  };

  const handleItemClicked = (jobType: IJobType) => {
    setFilterJobs(jobType);
    setOpenDropDown(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef}>
        <Button onClick={() => reload()}>{JobTypeMapping[filterJobs]}</Button>
        <Button
          size="small"
          aria-controls={openDropDown ? "split-button-menu" : undefined}
          aria-expanded={openDropDown ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggleDropdown}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={openDropDown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseDropdown}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuItems.map((option) => (
                    <MenuItem
                      key={option}
                      disabled={filterJobs === option}
                      selected={filterJobs === option}
                      onClick={() => handleItemClicked(option as IJobType)}
                    >
                      {JobTypeMapping[option]}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default JobTypeFilterButton;
