import AppConfigJson from "../config.json";
import { IAppConfig, IEnvConfig } from "../types/IAppConfig";

export default class Config {
  static url(): IEnvConfig {
    const AppConfig = AppConfigJson as IAppConfig;
    let config: IEnvConfig = AppConfig.DEV; // Defaults to DEV
    switch (process.env.REACT_APP_ENV) {
      case "DEV":
        config = AppConfig.DEV;
        break;
      case "STG":
        config = AppConfig.STG;
        break;
      case "PRD":
        config = AppConfig.PRD;
        break;
      default:
        break;
    }

    return config;
  }
}
