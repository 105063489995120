import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ManagerDashboard from "./ManagerDashboard";
import DriverDashboard from "./DriverDashboard";
import NotFound from "./NotFound";
import Loading from "../components/Loading";

function Dashboard() {
  const { token, role, error, isLoading } = useAuth();

  const isManager: boolean | undefined = useMemo(() => {
    if (role) {
      const userRoles = role.split(",");
      return !!userRoles?.find(
        (r) =>
          r.toLowerCase() === "manager" ||
          r.toLowerCase() === "delivery manager"
      );
    }

    return undefined;
  }, [role]);

  if (!token) {
    return <Navigate to="/" replace />;
  }

  if (isLoading || !role || isManager === undefined) {
    return <Loading />;
  }

  // Error with authentication
  if (error) {
    return <NotFound />;
  }

  return isManager ? <ManagerDashboard /> : <DriverDashboard />;
}

export default Dashboard;
