/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  Alert,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { IJobPhoto } from "../types/IJobPhoto";
import useAuth from "../hooks/useAuth";

interface PhotoListProps {
  photos: IJobPhoto[];
  onSelectedPhoto: (p: IJobPhoto) => void;
  onSelectedDeletePhoto: (p: IJobPhoto) => void;
}

export const acceptableFormats = [
  {
    ext: "png",
    type: "image/png",
  },
  {
    ext: "jpg",
    type: "image/jpeg",
  },
  {
    ext: "jpeg",
    type: "image/jpeg",
  },
];

export const getFileType = (filename: string) => {
  const splitByPeriod = filename.split(".");
  const extension = splitByPeriod.pop();
  return acceptableFormats.find((f) => f.ext === extension)?.type;
};

function PhotoList({
  photos,
  onSelectedPhoto,
  onSelectedDeletePhoto,
}: PhotoListProps) {
  const theme = useTheme();
  const { role } = useAuth();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  const isManager: boolean | undefined = React.useMemo(() => {
    if (role) {
      const userRoles = role.split(",");
      return !!userRoles?.find(
        (r) =>
          r.toLowerCase() === "manager" ||
          r.toLowerCase() === "delivery manager"
      );
    }

    return undefined;
  }, [role]);

  if (photos.length === 0) {
    return (
      <Alert severity="info">
        <Typography>No photo uploaded.</Typography>
      </Alert>
    );
  }

  return (
    <ImageList sx={{ width: "100%", height: "80%" }} cols={matchDownMd ? 1 : 3}>
      {photos.map((item, i) => (
        <ImageListItem key={`${item.Job_Id}-${i}`}>
          <img
            src={`data:${
              getFileType(item.FileName) || "image/png"
            };charset=utf-8;base64,${item.ImageData}`}
            srcSet={`data:${
              getFileType(item.FileName) || "image/png"
            };charset=utf-8;base64,${item.ImageData}`}
            alt={item.FileName}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.FileName}
            subtitle={`POD of Job ID ${item.Job_Id}`}
            actionIcon={
              <>
                {isManager && (
                  <IconButton
                    sx={{ color: "rgba(255, 0, 0, 0.8)" }}
                    aria-label={`Delete ${item.FileName}`}
                    onClick={() => onSelectedDeletePhoto(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`Delete ${item.FileName}`}
                  onClick={() => onSelectedPhoto(item)}
                >
                  <OpenInNewIcon />
                </IconButton>
              </>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export default PhotoList;
