import React from "react";
import { Box, CircularProgress, Typography, styled } from "@mui/material";

const StyledContainer = styled(Box)({
  flex: 1,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StyledText = styled(Typography)({
  marginTop: "1em",
});

function Loading() {
  return (
    <StyledContainer>
      <CircularProgress />
      <StyledText>Loading...</StyledText>
    </StyledContainer>
  );
}

export default Loading;
