import Http from "./Http";
import { IVehicleType } from "../types/IVehicleType";
import Config from "./Config";

const config = Config.url();

export default class VehicleType {
  /**
   * For Manager only: get all vehicle types
   * @param token
   * @returns
   */
  static async getAll(token: string | null): Promise<IVehicleType[]> {
    if (!token) {
      return [];
    }

    const vehicleResponse = await Http.get<IVehicleType[]>(
      token,
      `${config.url}/api/vehicles`
    );
    return vehicleResponse;
  }
}
