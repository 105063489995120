/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TimeslotsSelector from "./TimeslotsSelector";
import ErrorDialogContentText from "./ErrorDialogContentText";
import { dateToMuiString } from "../utils/FormatDate";
import { IGenericPostResponse } from "../types/IGenericPostResponse";

interface ExportReportDialogProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  onExportToExcel: (
    slot: string,
    fromDate: Date,
    toDate: Date
  ) => Promise<IGenericPostResponse>;
}

function ExportReportDialog({
  open,
  setOpen,
  onExportToExcel,
}: ExportReportDialogProps) {
  const today = new Date();
  const [fromDate, setFromDate] = React.useState<string>(
    dateToMuiString(today)
  );
  const [toDate, setToDate] = React.useState<string>(dateToMuiString(today));
  const [slot, setSlot] = React.useState<string>();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const handleExport = React.useCallback(async () => {
    if (!slot || !toDate || !fromDate) {
      setError("All fields are required.");
      return;
    }

    const toDateAsDate = new Date(toDate);
    const fromDateAsDate = new Date(fromDate);

    if (toDateAsDate < fromDateAsDate) {
      setError("To-Date must be after From-Date.");
      return;
    }

    setError(undefined); // Remove previous errors
    setIsSaving(true);

    const response = await onExportToExcel(slot, fromDateAsDate, toDateAsDate);
    if (response.status === "error") {
      setError(response.Message);
    }
    setIsSaving(false);
  }, [slot, toDate, fromDate, onExportToExcel]);

  const handleClose = () => {
    setFromDate(dateToMuiString(today));
    setToDate(dateToMuiString(today));
    setSlot(undefined);
    setIsSaving(false);
    setError(undefined);
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Export Delivered Jobs to Excel</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <TimeslotsSelector onChange={setSlot} showAllTimeslotOption />
        </Box>
        <TextField
          id="report-date-from"
          fullWidth
          size="small"
          sx={{ mt: 3, mb: 1 }}
          label="From Date"
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="report-date-to"
          fullWidth
          size="small"
          sx={{ mt: 2, mb: 1 }}
          label="To Date"
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button
          onClick={handleExport}
          variant="contained"
          disabled={isSaving}
          endIcon={<FileDownloadIcon />}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportReportDialog;
