import React from "react";
import { createHashRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import App from "./App";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        index: true,
        element: <Login />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
