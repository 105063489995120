import React, { useMemo } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Divider,
  IconButton,
  Pagination,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { Navigate } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import JobCompleteDialog from "../components/JobCompleteDialog";
import { IJobType } from "../types/IJobType";
import useJobs from "../hooks/useJobs";
import JobsList from "../components/JobsList";
import Loading from "../components/Loading";
import useAuth from "../hooks/useAuth";
import Jobs, {
  JobCompleteFunc,
  JobFailedFunc,
  JobViewPhotosFunc,
} from "../repositories/Jobs";
import JobFailedDialog from "../components/JobFailedDialog";
import TimeslotsSelector from "../components/TimeslotsSelector";

const StyledContainerBox = styled(Box)({
  flex: 1,
  paddingBottom: 60,
  height: "100%",
});

const StyledFilterBarBox = styled(Box)({
  flex: 1,
  flexDirection: "column",
  backgroundColor: "#f5f5f5",
  padding: 12,
});

const StyledTitle = styled(Typography)({
  fontSize: 24,
  fontWeight: "bold",
  padding: 12,
  paddingBottom: 0,
  margin: 0,
}) as typeof Typography;

type BottomNavValues = "pending" | "completed";

function DriverDashboard() {
  const { token } = useAuth();
  const [navValue, setNavValue] = React.useState<BottomNavValues>("pending");
  const [completedJobId, setCompletedJobId] = React.useState<number>();
  const [failedJobId, setFailedJobId] = React.useState<number>();
  const [filterJobs, setFilterJobs] = React.useState<IJobType>("A");
  const [orderBy, setOrderby] = React.useState<"ASC" | "DESC">("ASC");
  const [orderNumber, setOrderNumber] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [toggleFilterBar, setToggleFilterBar] = React.useState<boolean>(false);
  const [filterFromDate, setFilterFromDate] = React.useState<string>("");
  const [filterToDate, setFilterToDate] = React.useState<string>("");
  const [filterTimeslot, setFilterTimeslot] = React.useState<string>("");

  const {
    jobs,
    isLoading: isLoadingJobs,
    pagination,
    reload,
  } = useJobs(
    filterJobs,
    orderBy,
    currentPage,
    filterFromDate,
    filterToDate,
    orderNumber,
    undefined,
    filterTimeslot
  );

  const handleComplete: JobCompleteFunc = React.useCallback(
    async (jobId: number, comments: string, files: File[]) =>
      Jobs.complete(token, jobId, comments, files),
    [token]
  );

  const handleFailed: JobFailedFunc = React.useCallback(
    async (jobId: number, comments: string, files: File[]) =>
      Jobs.failed(token, jobId, comments, files),
    [token]
  );

  const handleActionClick = (action: "complete" | "failed", jobId: number) => {
    switch (action) {
      case "complete":
        setCompletedJobId(jobId);
        break;
      case "failed":
        setFailedJobId(jobId);
        break;
      default:
        break;
    }
  };

  const clearAllFilters = React.useCallback(() => {
    setOrderNumber("");
    setFilterFromDate("");
    setFilterToDate("");
    setFilterTimeslot("");
    setCurrentPage(1);
    setToggleFilterBar(false);
  }, []);

  const handleChangeFromDate = React.useCallback((fromDate: string) => {
    setFilterFromDate(fromDate);
    setFilterToDate((prev) => (prev === "" ? fromDate : prev)); // Set toDate to be fromDate if it's empty
    setCurrentPage(1);
  }, []);

  const handleChangeToDate = React.useCallback((toDate: string) => {
    setFilterToDate(toDate);
    setFilterFromDate((prev) => (prev === "" ? toDate : prev)); // Set fromDate to be toDate if it's empty
    setCurrentPage(1);
  }, []);

  const onViewPhotos: JobViewPhotosFunc = React.useCallback(
    async (jobId: number) => Jobs.getJobPhotos(token, jobId),
    [token]
  );

  const completedOrderNumber = useMemo((): string => {
    const job = jobs.find((j) => j.Id === completedJobId);
    if (!job) {
      return "";
    }
    return job.So_Nbr || job.Ord_Nbr;
  }, [jobs, completedJobId]);

  const failedOrderNumber = useMemo((): string => {
    const job = jobs.find((j) => j.Id === failedJobId);
    if (!job) {
      return "";
    }
    return job.So_Nbr || job.Ord_Nbr;
  }, [jobs, failedJobId]);

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return (
    <StyledContainerBox id="dashboard-driver" data-testid="dashboard-driver">
      <Box sx={{ display: "flex", padding: "6px 0" }}>
        <StyledTitle component="h1">Jobs</StyledTitle>
        <Box sx={{ flex: 1 }} />
        {(!!orderNumber ||
          !!filterFromDate ||
          !!filterToDate ||
          !!filterTimeslot) && (
          <Button aria-label="Clear All Filters" onClick={clearAllFilters}>
            Clear Filters
          </Button>
        )}
        <IconButton onClick={() => setToggleFilterBar((prev) => !prev)}>
          <FilterAltIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            setOrderby((prev) => (prev === "ASC" ? "DESC" : "ASC"))
          }
        >
          <SortByAlphaIcon />
        </IconButton>
        <IconButton onClick={() => reload()}>
          <ReplayIcon />
        </IconButton>
      </Box>
      <Divider sx={{ width: "100%" }} />
      {toggleFilterBar && (
        <StyledFilterBarBox>
          <TextField
            fullWidth
            size="small"
            label="Search Order #"
            type="text"
            sx={{ mb: 2 }}
            value={orderNumber}
            onChange={(e) => {
              setOrderNumber(e.target.value);
              setCurrentPage(1); // Resets to page 1
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            label="From Created Date"
            type="date"
            sx={{ mr: 1, mb: 2 }}
            value={filterFromDate}
            onChange={(e) => {
              handleChangeFromDate(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            label="To Created Date"
            type="date"
            sx={{ mb: 2 }}
            value={filterToDate}
            onChange={(e) => {
              handleChangeToDate(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TimeslotsSelector
            onChange={(timeslot) => setFilterTimeslot(timeslot)}
            showAllTimeslotOption
          />
          <Button sx={{ mt: 2 }} variant="contained" onClick={clearAllFilters}>
            Clear All Filters
          </Button>
        </StyledFilterBarBox>
      )}
      {isLoadingJobs ? (
        <Loading />
      ) : (
        <JobsList
          jobs={jobs}
          jobType={filterJobs}
          onActionClick={handleActionClick}
          onViewPhotos={onViewPhotos}
        />
      )}
      {pagination && pagination.TotalPages > 1 && (
        <Pagination
          sx={{ mb: 2, mt: 1 }}
          count={pagination.TotalPages}
          page={currentPage}
          onChange={(e, page) => {
            setCurrentPage(page);
            window.scrollTo(0, 0);
          }}
        />
      )}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={navValue}
          onChange={(_e, v: BottomNavValues) => setNavValue(v)}
        >
          <BottomNavigationAction
            value="pending"
            label="Pending"
            icon={<ListAltIcon />}
            onClick={() => setFilterJobs("A")}
          />
          <BottomNavigationAction
            value="delivered"
            label="Delivered"
            icon={<FactCheckIcon />}
            onClick={() => setFilterJobs("D")}
          />
          <BottomNavigationAction
            value="completed"
            label="Completed"
            icon={<FactCheckIcon />}
            onClick={() => setFilterJobs("Z")}
          />
          <BottomNavigationAction
            value="failed"
            label="Failed"
            icon={<EventBusyIcon />}
            onClick={() => setFilterJobs("R")}
          />
        </BottomNavigation>
      </Paper>
      <JobCompleteDialog
        jobId={completedJobId}
        setJobId={setCompletedJobId}
        onComplete={handleComplete}
        reload={reload}
        orderNumber={completedOrderNumber}
      />
      <JobFailedDialog
        jobId={failedJobId}
        setJobId={setFailedJobId}
        onFailed={handleFailed}
        reload={reload}
        orderNumber={failedOrderNumber}
      />
    </StyledContainerBox>
  );
}

export default DriverDashboard;
