/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { JobDialogProps } from "./JobAssignDialog";
import { JobCompleteFunc } from "../repositories/Jobs";
import ErrorDialogContentText from "./ErrorDialogContentText";

export interface JobCompleteDialogProps extends JobDialogProps {
  onComplete: JobCompleteFunc;
  reload: () => void;
  orderNumber: string;
}

function JobCompleteDialog({
  jobId,
  setJobId,
  onComplete,
  reload,
  orderNumber,
}: JobCompleteDialogProps) {
  const [comments, setComments] = React.useState<string>("Delivered");
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<File[] | undefined>(undefined);
  const [helperText, setHelperText] = React.useState<string>("9 / 400");
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);

  React.useEffect(() => {
    setDisableSubmit(comments.length === 0 || comments.length > 400 || !files);
  }, [comments, files]);

  const handleClickComplete = React.useCallback(async () => {
    if (!jobId || !comments || !files) {
      setError("All fields are required.");
      return;
    }

    setIsSaving(true);

    // Upload all the files
    const response = await onComplete(jobId, comments, files);

    if (response.status === "success") {
      setComments("Delivered");
      setFiles(undefined);
      setError("");
      setIsSaving(false);
      reload();
      setJobId(undefined);
      setHelperText("9 / 400");
    } else {
      setError("Server returned error. Please try again.");
    }

    setIsSaving(false);
  }, [comments, files, jobId, onComplete, reload, setJobId]);

  const handleClose = () => {
    setComments("Delivered");
    setError("");
    setFiles(undefined);
    setJobId(undefined);
    setHelperText("9 / 400");
  };

  const handleChangeComments = React.useCallback((newComments: string) => {
    if (newComments.length > 400) {
      setError("Exceeded 400 characters.");
    } else {
      setError("");
    }
    setComments(newComments);
    setHelperText(`${newComments.length} / 400`);
  }, []);

  // Check if OS is android (or Linux because some mobile will return Linux)
  const isAndroidLinux = React.useCallback((): boolean => {
    const { userAgent } = navigator;
    if (userAgent.match(/Android/i) || userAgent.match(/Linux/i)) {
      return true;
    }
    return false;
  }, []);

  return (
    <Dialog
      open={!!jobId}
      onClose={handleClose}
      aria-labelledby="alert-dialog-complete-title"
      aria-describedby="alert-dialog-complete-description"
    >
      <DialogTitle id="alert-dialog-complete-title">
        Completed selected Job?
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 1, fontWeight: "700" }}>
          Order #: {orderNumber}
        </Typography>
        <DialogContentText
          id="alert-dialog-complete-description"
          sx={{ marginBottom: 3 }}
        >
          You must attach a photo as proof and include a message for the
          completed job.
        </DialogContentText>
        <MuiFileInput
          label="Upload photo"
          value={files}
          onChange={(newFiles) => setFiles(newFiles)}
          inputProps={isAndroidLinux() ? {} : { accept: ".png, .jpeg" }}
          multiple
        />
        {files && files.length > 1 && (
          <Box sx={{ p: 1 }}>
            <Typography variant="body1" sx={{ color: "#999" }}>
              Selected files:
            </Typography>
            {files.map((f) => (
              <Typography variant="body2" key={f.name}>
                {f.name}
              </Typography>
            ))}
          </Box>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Message for completion"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          minRows={3}
          value={comments}
          onChange={(e) => handleChangeComments(e.target.value)}
          helperText={helperText}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button
          onClick={handleClickComplete}
          variant="contained"
          disabled={isSaving || disableSubmit}
        >
          Submit
        </Button>
      </DialogActions>
      {error && (
        <ErrorDialogContentText sx={{ pl: 3, pr: 3, pb: 2 }}>
          {error}
        </ErrorDialogContentText>
      )}
    </Dialog>
  );
}

export default JobCompleteDialog;
