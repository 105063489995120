import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  FormGroup,
  Pagination,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SummarizeIcon from "@mui/icons-material/Summarize";
import useAuth from "../hooks/useAuth";
import JobsTable from "../components/JobsTable";
import useDrivers from "../hooks/useDrivers";
import useJobs from "../hooks/useJobs";
import Loading from "../components/Loading";
import useVehicleTypes from "../hooks/useVehicleTypes";
import Jobs, {
  JobAssignFunc,
  JobCancelFunc,
  JobCloseFunc,
  JobViewPhotosFunc,
} from "../repositories/Jobs";
import { IJobType } from "../types/IJobType";
import JobTypeFilterButton from "../components/JobTypeFilterButton";
import { IGenericPostResponse } from "../types/IGenericPostResponse";
import JobBulkAssignDialog from "../components/JobBulkAssignDialog";
import ExportReportDialog from "../components/ExportReportDialog";
import { dateToApiString } from "../utils/FormatDate";

const StyledContainerBox = styled(Box)(() => ({
  flex: 1,
  paddingBottom: 60,
  height: "100%",
}));

const StyledFilterStack = styled(Stack)({
  alignItems: "center",
  padding: 10,
  "& #job-filter-label": {
    padding: 10,
  },
});

function ManagerDashboard() {
  const { token } = useAuth();
  const { drivers, isLoading: isLoadingDrivers } = useDrivers();
  const { vehicles, isLoading: isLoadingVehicles } = useVehicleTypes();
  const [filterJobs, setFilterJobs] = React.useState<IJobType>("O");

  const [orderBy, setOrderby] = React.useState<"ASC" | "DESC">("ASC");
  const [orderNumber, setOrderNumber] = React.useState<string>("");
  const [filterFromDate, setFilterFromDate] = React.useState<string>("");
  const [filterToDate, setFilterToDate] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [driver, setDriver] = React.useState<string>("");

  const [bulkAction, setBulkAction] = React.useState<boolean>(false);
  const [showBulkAssignBtn, setShowBulkAssignBtn] =
    React.useState<boolean>(false);
  const bulkAssignList = React.useRef<number[]>([]);
  const [openBulkAssignDialog, setOpenBulkAssignDialog] =
    React.useState<boolean>(false);
  const [openExportDialog, setOpenExportDialog] =
    React.useState<boolean>(false);

  const {
    jobs,
    isLoading: isLoadingJobs,
    pagination,
    reload,
  } = useJobs(
    filterJobs,
    orderBy,
    currentPage,
    filterFromDate,
    filterToDate,
    orderNumber,
    driver
  );

  // Reset on unmount
  React.useEffect(
    () => () => {
      setFilterFromDate("");
      setFilterToDate("");
      setFilterJobs("O");
      setDriver("");
      setCurrentPage(1);
    },
    []
  );

  const onAssign: JobAssignFunc = React.useCallback(
    async (jobId: number, driverId: string, slot: string, vehicleId: string) =>
      Jobs.assign(token, jobId, driverId, slot, vehicleId),
    [token]
  );

  const onCancel: JobCancelFunc = React.useCallback(
    async (jobId: number, comments: string) =>
      Jobs.cancel(token, jobId, comments),
    [token]
  );

  const onClose: JobCloseFunc = React.useCallback(
    async (jobId: number) => Jobs.close(token, jobId),
    [token]
  );

  const onViewPhotos: JobViewPhotosFunc = React.useCallback(
    async (jobId: number) => Jobs.getJobPhotos(token, jobId),
    [token]
  );

  const clearAllFilters = React.useCallback(() => {
    setDriver("");
    setOrderNumber("");
    setFilterFromDate("");
    setFilterToDate("");
    setCurrentPage(1);
  }, []);

  const onExportToExcel = React.useCallback(
    async (
      slot: string,
      fromDate: Date,
      toDate: Date
    ): Promise<IGenericPostResponse> => {
      const download = async (): Promise<IGenericPostResponse> => {
        const fromDateString = dateToApiString(fromDate);
        const toDateString = dateToApiString(toDate);

        try {
          const result = await Jobs.exportToExcel(
            token,
            slot,
            fromDateString,
            toDateString
          );
          if (result.blob) {
            const blobUrl = window.URL.createObjectURL(result.blob);
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = result.filename;
            document.body.appendChild(a); // Workaround for Firefox
            a.click();
            a.remove(); // Remove after click
          }

          return {
            status: "success",
          } as IGenericPostResponse;
        } catch (e) {
          return {
            status: "error",
            Message: "Server has returned an error.",
          } as IGenericPostResponse;
        }
      };

      return download();
    },
    [token]
  );

  const onBulkAssign = (jobId: number, checked: boolean) => {
    const found = bulkAssignList.current.findIndex((i) => i === jobId);
    if (checked) {
      if (found < 0) {
        // Add to list
        bulkAssignList.current.push(jobId);
      }
    } else if (found > -1) {
      // Remove from list
      bulkAssignList.current.splice(found, 1);
    }

    setShowBulkAssignBtn(bulkAction && bulkAssignList.current.length > 0);
  };

  const handleBulkAssignBtnClicked = React.useCallback(() => {
    setOpenBulkAssignDialog(true);
  }, []);

  // Handle bulk assigning of jobs
  const handleBulkAssign = React.useCallback(
    async (driverId: string, slot: string, vehicleId: string) => {
      const errorMsg: string[] = [];
      const erroredList: number[] = [];
      if (bulkAssignList.current.length > 0) {
        await Promise.all(
          bulkAssignList.current.map(async (jobId) => {
            try {
              const response = await Jobs.assign(
                token,
                jobId,
                driverId,
                slot,
                vehicleId
              );
              if (response.status !== "success") {
                erroredList.push(jobId);
                errorMsg.push(
                  `Unable to assign job ID: ${jobId}, ${
                    response.Message ?? "unknown reason"
                  }.`
                );
              }
            } catch (e) {
              errorMsg.push(`Unable to assign job ID: ${jobId}.`);
            }
          })
        );
      }
      bulkAssignList.current = erroredList; // Reset
      setShowBulkAssignBtn(erroredList.length > 0);
      return {
        status: errorMsg.length > 0 ? "error" : "success",
        Message: errorMsg.length > 0 ? errorMsg.join(" ") : undefined,
      } as IGenericPostResponse;
    },
    [token]
  );

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return (
    <StyledContainerBox id="dashboard-manager" data-testid="dashboard-manager">
      <StyledFilterStack direction="row">
        <Typography id="job-filter-label">Jobs Status</Typography>
        <JobTypeFilterButton
          filterJobs={filterJobs}
          setFilterJobs={setFilterJobs}
          reload={reload}
        />
        {["O", "A"].includes(filterJobs) && (
          <>
            <Box sx={{ width: 24 }} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={bulkAction}
                    onChange={() =>
                      setBulkAction((prev) => {
                        if (prev) {
                          // Disabling bulk action
                          bulkAssignList.current = [];
                          setShowBulkAssignBtn(false);
                        }
                        return !prev;
                      })
                    }
                    inputProps={{ "aria-label": "bulk-action" }}
                  />
                }
                label="Bulk Assign"
              />
            </FormGroup>
            {showBulkAssignBtn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleBulkAssignBtnClicked()}
              >
                Assign
              </Button>
            )}
          </>
        )}
        <Box sx={{ flex: 1 }} />
        {(!!orderNumber || !!filterFromDate || !!filterToDate || !!driver) && (
          <Button aria-label="Clear All Filters" onClick={clearAllFilters}>
            Clear Filters
          </Button>
        )}
        <TextField
          size="small"
          label="Driver"
          type="text"
          sx={{ mr: 1 }}
          value={driver}
          onChange={(e) => {
            setDriver(e.target.value);
            setCurrentPage(1); // Resets to page 1
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          size="small"
          label="Order Number"
          type="text"
          sx={{ mr: 1 }}
          value={orderNumber}
          onChange={(e) => {
            setOrderNumber(e.target.value);
            setCurrentPage(1); // Resets to page 1
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          size="small"
          label="From Created Date"
          type="date"
          sx={{ mr: 1 }}
          value={filterFromDate}
          onChange={(e) => {
            setFilterFromDate(e.target.value);
            setCurrentPage(1); // Resets to page 1
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          size="small"
          label="To Created Date"
          type="date"
          sx={{ mr: 1 }}
          value={filterToDate}
          onChange={(e) => {
            setFilterToDate(e.target.value);
            setCurrentPage(1); // Resets to page 1
          }}
          InputLabelProps={{ shrink: true }}
        />
        <ButtonGroup>
          <Tooltip title="Order By Created Date">
            <Button
              aria-label="Order By Created Date"
              onClick={() =>
                setOrderby((prev) => (prev === "ASC" ? "DESC" : "ASC"))
              }
              endIcon={
                orderBy === "DESC" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
              }
            >
              {orderBy === "ASC" ? "A-Z" : "Z-A"}{" "}
            </Button>
          </Tooltip>
          <Tooltip title="Reload page">
            <Button onClick={() => reload()} aria-label="reload page">
              <ReplayIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download Reports">
            <Button
              onClick={() => setOpenExportDialog(true)}
              aria-label="Download Reports"
            >
              <SummarizeIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </StyledFilterStack>
      {isLoadingJobs || isLoadingDrivers || isLoadingVehicles ? (
        <Loading />
      ) : (
        <>
          <JobsTable
            bulkAction={bulkAction}
            jobs={jobs}
            drivers={drivers}
            vehicles={vehicles}
            filteredJobsBy={filterJobs}
            onAssign={onAssign}
            onCancel={onCancel}
            onClose={onClose}
            onViewPhotos={onViewPhotos}
            reload={reload}
            onBulkAssign={onBulkAssign}
          />
          <Divider />
          {pagination && (
            <Pagination
              count={pagination.TotalPages}
              page={pagination.Page}
              onChange={(e, p) => setCurrentPage(p)}
              size="small"
              sx={{ m: 1 }}
            />
          )}
          <JobBulkAssignDialog
            drivers={drivers}
            vehicles={vehicles}
            jobIds={bulkAssignList.current}
            open={openBulkAssignDialog}
            setOpen={setOpenBulkAssignDialog}
            onBulkAssign={handleBulkAssign}
            onCancel={() => {
              bulkAssignList.current = [];
              setShowBulkAssignBtn(false);
            }}
            reload={reload}
          />
          <ExportReportDialog
            open={openExportDialog}
            setOpen={setOpenExportDialog}
            onExportToExcel={onExportToExcel}
          />
        </>
      )}
    </StyledContainerBox>
  );
}

export default ManagerDashboard;
