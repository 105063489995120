/**
 * O: OPEN for allocation
 * A: Assigned
 * C: Cancelled
 * D: Delivered
 * R: Rejected
 * I: Initiated
 * Z: Completed
 */
export type IJobType = "A" | "C" | "D" | "O" | "R" | "Z";

export const JobTypeMapping: { [key: string]: string } = {
  O: "Open",
  A: "Assigned",
  C: "Cancelled",
  D: "Delivered",
  R: "Failed",
  Z: "Completed",
};
