/* eslint-disable no-void */
import React from "react";
import { IDriver } from "../types/IDriver";
import { AuthContext } from "../contexts/AuthContext";
import Driver from "../repositories/Driver";

export default function useDrivers() {
  const { token } = React.useContext(AuthContext);
  const [drivers, setDrivers] = React.useState<IDriver[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (token) {
      void (async () => {
        setIsLoading(true);
        setDrivers(await Driver.getAll(token));
        setIsLoading(false);
      })();
    }

    return () => {
      setDrivers([]); // Reset to empty array on dismount
    };
  }, [token]);

  return {
    drivers,
    isLoading,
  };
}
