/* eslint-disable no-void */
import React from "react";
import { AuthContext } from "../contexts/AuthContext";
import { IVehicleType } from "../types/IVehicleType";
import VehicleType from "../repositories/VehicleType";

export default function useVehicleTypes() {
  const { token } = React.useContext(AuthContext);
  const [vehicles, setVehicles] = React.useState<IVehicleType[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (token) {
      void (async () => {
        setIsLoading(true);
        const fetched = await VehicleType.getAll(token);
        fetched.sort((a, b) => {
          // Always put 0 as first
          if (a.Id === 0) {
            return -1; // a before b
          }
          if (b.Id === 0) {
            return 1; // b before a
          }

          // Otherwise, sort by name
          const nameA = a.VehicleName ? a.VehicleName.toLowerCase() : "";
          const nameB = b.VehicleName ? b.VehicleName.toLowerCase() : "";

          if (nameA < nameB) {
            return -1; // a before b
          }

          if (nameA > nameB) {
            return 1; // b before a
          }

          return 0; // same
        });
        setVehicles(fetched);
        setIsLoading(false);
      })();
    }

    return () => {
      setVehicles([]); // Reset to empty array on dismount
    };
  }, [token]);

  return {
    vehicles,
    isLoading,
  };
}
