import React, { useEffect } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { IJobPhoto } from "../types/IJobPhoto";

type PhotoDeleteDialogProps = {
  photo: IJobPhoto;
  visible: boolean;
  onClose: () => void;
  onDelete: (p: IJobPhoto) => Promise<boolean>;
};

export default function PhotoDeleteDialog({
  photo,
  visible,
  onClose,
  onDelete,
}: PhotoDeleteDialogProps) {
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [deleteStatus, setDeleteStatus] = React.useState<{
    state: "success" | "error";
    message: string;
  }>();

  useEffect(
    () =>
      // Reset Delete status
      () => {
        setDeleteStatus(undefined);
      },
    []
  );

  const handleDeleting = React.useCallback(() => {
    setIsDeleting(true);
    onDelete(photo)
      .then((result) => {
        setDeleteStatus({
          state: result ? "success" : "error",
          message: result ? "Photo deleted" : "Unable to delete photo",
        });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [onDelete, photo]);

  const handleClosing = React.useCallback(() => {
    setIsDeleting(false);
    onClose();
  }, [onClose]);

  return (
    <Dialog open={visible}>
      <DialogTitle sx={{ fontSize: "16px" }}>Photo Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {photo.FileName} from Job{" "}
          {photo.Job_Id}?
        </DialogContentText>
        {deleteStatus ? (
          <Alert
            variant="standard"
            severity={deleteStatus.state}
            sx={{ mt: 2 }}
          >
            {deleteStatus.message}
          </Alert>
        ) : (
          <Alert severity="error" sx={{ mt: 2 }}>
            This action cannot be undone.
          </Alert>
        )}
      </DialogContent>
      {!deleteStatus ? (
        <DialogActions>
          <Button
            variant="text"
            onClick={() => handleClosing()}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleting()}
            disabled={isDeleting}
          >
            {isDeleting && (
              <CircularProgress color="inherit" size={14} sx={{ mr: 2 }} />
            )}{" "}
            Delete
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button variant="contained" onClick={() => handleClosing()}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
