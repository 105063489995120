import { IDriver } from "../types/IDriver";
import Config from "./Config";
import Http from "./Http";

const config = Config.url();

export default class Driver {
  /**
   * For Manager only: get all drivers
   * @param token
   * @returns
   */
  static async getAll(token: string | null): Promise<IDriver[]> {
    if (!token) {
      return [];
    }

    const driverResponse = await Http.get<IDriver[]>(
      token,
      `${config.url}/api/drivers`
    );
    return driverResponse;
  }
}
