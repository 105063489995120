export default class Http {
  /**
   * Generic POST method with JSON
   * @param url
   * @param config
   * @returns
   */
  static async post<T>(
    url: RequestInfo | URL,
    config?: RequestInit,
    token?: string
  ): Promise<T> {
    let headers: HeadersInit = {
      "Content-Type": "application/json",
      Accept: "application/json",
      cache: "no-cache",
    };

    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const init: RequestInit = {
      ...config,
      method: "POST",
      headers,
    };
    const response = await fetch(url, init);
    return response.json() as T;
  }

  /**
   * Generic GET method with JSON
   * @param token
   * @param url
   * @param config
   * @returns
   */
  static async get<T>(
    token: string,
    url: RequestInfo | URL,
    config?: RequestInit
  ): Promise<T> {
    const init: RequestInit = {
      ...config,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        cache: "no-cache",
      },
    };
    const response = await fetch(url, init);
    return response.json() as T;
  }

  /**
   * Special POST method for uploading file
   * @param url
   * @param config
   * @returns
   */
  static async postFormData<T>(
    url: RequestInfo | URL,
    body: FormData,
    token: string
  ): Promise<T> {
    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      cache: "no-cache",
    };

    const requestOptions: RequestInit = {
      method: "POST",
      headers,
      body,
    };

    const response = await fetch(url, requestOptions);
    return response.json() as T;
  }
}
