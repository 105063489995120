/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { JobCloseFunc } from "../repositories/Jobs";
import ErrorDialogContentText from "./ErrorDialogContentText";
import { JobDialogProps } from "./JobAssignDialog";

interface JobCloseDialogProps extends JobDialogProps {
  onClose: JobCloseFunc;
  reload: () => void;
}

function JobCloseDialog({
  jobId,
  setJobId,
  onClose,
  reload,
}: JobCloseDialogProps) {
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const handleCloseJob = React.useCallback(async () => {
    if (!jobId) {
      setError("You did not select a job.");
      return;
    }

    setIsSaving(true);
    const response = await onClose(jobId);
    if (response.status === "success") {
      setError("");
      setIsSaving(false);
      reload();
      setJobId(undefined);
    } else {
      setError(
        (response.Message as string) ??
          "Server returned error. Please try again."
      );
    }
    setIsSaving(false);
  }, [jobId, onClose, setJobId, reload]);

  const handleClose = () => {
    reload();
    setError("");
    setJobId(undefined);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!jobId}
      onClose={handleClose}
      sx={{
        "& .MuiFormControl-root": {
          marginBottom: "1em",
        },
      }}
    >
      <DialogTitle>Completed selected Job?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confirm Job ID {jobId} has completed and all documentation collected
          from driver.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseJob}
          variant="contained"
          disabled={isSaving}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobCloseDialog;
