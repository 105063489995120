import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IDriver } from "../types/IDriver";

interface DriversSelectorProps {
  drivers: IDriver[];
  onChange: (d: string) => void;
}

const sortByDriverName = (a: IDriver, b: IDriver) => {
  const aFullname = `${a.LastName || ""} ${a.FirstName || ""}`;
  const bFullname = `${b.LastName || ""} ${b.FirstName || ""}`;

  // Sort by last name
  if (aFullname > bFullname) {
    return 1;
  }
  if (aFullname < bFullname) {
    return -1;
  }

  return 0; // equal
};

function DriversSelector({ drivers, onChange }: DriversSelectorProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="select-driver-label">Select Driver</InputLabel>
      <Select
        labelId="select-driver-label"
        id="select-driver"
        label="Select Driver"
        onChange={handleChange}
        defaultValue=""
      >
        {drivers.sort(sortByDriverName).map((d) => (
          <MenuItem key={d.Id} value={d.Id}>
            {d.FirstName}
            {d.FirstName && " "}
            {d.LastName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DriversSelector;
